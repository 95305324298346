/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const LOGOUT_AUTH_SUCCESS = 'LOGOUT_AUTH_SUCCESS';
export const LOGOUT_AUTH_FAIL = 'LOGOUT_AUTH_FAIL';

export const SAVE_AUTH = 'SAVE_AUTH';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const RESET_USER = 'RESET_USER';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const RESET_USERS = 'RESET_USERS';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS';
