import { SAVE_AUTH, LOGOUT_AUTH } from '../constants';

export default function auth(
  state = {
    user: null,
  },
  action,
) {
  switch (action.type) {
    case SAVE_AUTH:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_AUTH:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
