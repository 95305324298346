import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import auth from './auth';
import runtime from './runtime';
import editUser from './editUser';
import users from './users';
import transactions from './transactions';

export default function createRootReducer() {
  return combineReducers({
    auth,
    runtime,
    form: formReducer,
    editUser,
    users,
    transactions,
  });
}
