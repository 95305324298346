import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  RESET_TRANSACTIONS,
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  transactions: [],
  total: 0,
};

export default function transactions(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.result.json,
        total: action.result.total,
        loading: false,
        loaded: true,
      };
    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case RESET_TRANSACTIONS:
      return initialState;
    default:
      return state;
  }
}
