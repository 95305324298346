import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  RESET_USER,
} from '../constants';

const initialState = {
  loading: false,
  user: {
    password: '',
  },
};

export default function editUser(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS: {
      const user = action.result.json;

      return {
        ...state,
        user,
        loading: false,
      };
    }
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
}
